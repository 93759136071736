.statistics {
    background-color: hsl(0, 0%, 95%);
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    margin-top: 20px;
}

#advanced-statistics {
    display: inline;
    font-family: Poppins;
    font-weight: 500;
    position: relative;
    margin-left: 35%;
}

#advanced-statistics > h2 {
    font-size: 2rem;
    color:  black;
    margin: 2rem auto;
    text-align: center;
}

#advanced-statistics > p {
    font-size: 1.2rem;
    color:  gray;
    margin: 1rem auto;
    text-align: center;
    width: 100%;
}

.cards {
    display: flex;
    justify-content: space-around;
    margin: 4rem auto;
    width: 100%;
}

.card {
    font-family: poppins;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 4rem;
    width: 26rem;
}

.cards > .card > .card-img > img {
    background-color: hsl(257, 27%, 26%);
    border-radius: 50%;
    border: 1px solid black;
    padding: 15px;
    position: absolute;
    top: -1.5rem;
}

.card:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

/* Media Queries */

@media screen and (max-width: 1440px) {
    .statistics > .cards > .card {
        margin: 0rem 0.5rem;
    }
}

@media screen and (max-width: 1024px) {
    .cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .statistics > .cards > .card {
        margin: 2rem 3rem;
    }
  
}

@media screen and (max-width: 768px) {
    .cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .statistics > .cards > .card {
        margin: 2rem 3rem;
    }
    
}

@media screen and (max-width: 468px) {
    .cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .statistics > .cards > .card {
        width: 98%;
    }
}

@media screen and (max-width: 375px) {
    .cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .statistics > .cards > .card {
        width: 98%;
    }    
}

@media screen and (max-width: 320px) {
    .cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .statistics > .cards > .card {
        width: 98%;
    }        
}