.input-container {
    background-image: url("./bg-shorten-desktop.svg");
    width: 67%;
    position: relative;
    left: 17.8%;
    display: flex;
    justify-content: center;
    background-color: hsl(257, 27%, 26%);
    border-radius: 10px;
    padding: 2rem;
}

input {
    width: 50%;
    height: 100%;
    outline: none;
    font-family: Poppins;   
    font-weight: 500;

    border: 1px solid hsl(0, 0%, 75%);
    border-radius: 5px;

    padding: 1rem;
}

input:focus {
    border: 1px solid hsl(180, 66%, 49%);
}

.input-container > button {
    padding: 0.6rem 1.5rem;
    position: relative;
    left: 1rem;
    background-color: hsl(180, 66%, 49%);
    border: none;
    border-radius: 0.5rem;
    color: white;
    font-family: Poppins;
    font-weight: 700;
}

button:hover {
    cursor: pointer;
}


@media screen and (max-width: 1024px) {
    .input-container {
        background-image: url("./bg-shorten-mobile.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 65%;
        left: 0;
        margin-left: 0;

        margin: 0 auto;
    }

    input {
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .input-container {
        background-image: url("./bg-shorten-mobile.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 75%;
        height: 8rem;
        
        margin: 0 auto;
    }

    input {
        width: 100%;
    }
}