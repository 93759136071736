@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');

root {
  --background-color: white;
  --text-color: black;

  /*Primary Colors*/
  --button-color: hsl(180, 66%, 49%);
  --dark-violet: hsl(257, 27%, 26%);

  /*Neutral Colors*/
  --gray: hsl(0, 0%, 75%);
  --grayish-violet: hsl(257, 7%, 63%);
  --very-dark-blue: hsl(255, 11%, 22%);
  --very-dark-violet: hsl(260, 8%, 14%);

  /*Font*/
  --font-family: 'Poppins', sans-serif;
  --font-weight: 500;
  --font-weight-bold: 700;
}

.container {
  background-color: var(--background-color);
  color: var(--text-color);
}

.header-container {
  display: flex;
  justify-content: space-between;
}

nav {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
  margin: 2rem;
}

header > .header-container > nav > h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

header > .header-container > nav > .list {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 2rem;
  
}

header > .header-container > nav > .list > a {
  text-decoration: none;
}

header > .header-container > nav > .list > a:hover {
  color: var(--dark-violet);
}

header > .header-container > nav > .login > a {
  text-decoration: none;
  position: relative;
  text-align: right;
  left: 250%;
}

header > .header-container > nav > .login > #sign-up {
  border: 1px solid hsl(180, 66%, 49%);
  border-radius: 1.5rem;
  background-color: hsl(180, 66%, 49%);
  padding: 0.5rem 1rem;
  margin: 2rem;
  margin-right: 5rem;
  color: white;
}

header > .header-container > nav > .login > #sign-up:hover {
  background-color: white;
  color: hsl(180, 66%, 49%);
}



.output > button.Copied {
  background-color: hsl(257, 27%, 26%);
  color: orange;
}

.output > button.Copied:hover {
  background-color: hsl(180, 66%, 49%);
  color: white;
}

.output > button {
  font-family: poppins;
  font-weight: 500;
  background-color: hsl(180, 66%, 49%);
  color: white;
  border: none;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  margin: 2rem;
  margin-left: 0;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  header > .header-container > nav > .login > #sign-up {
    margin-right: 2rem;
  }

  header > .header-container > nav > .list > a {
    font-size: 0.8rem;
  }

  header > .header-container > nav > .login > a {
    font-size: 0.8rem;
    position: relative;
    text-align: right;
    left: 250%;
  }

  header > .header-container > nav > .login > #sign-up {
    font-size: 0.8rem;
  }

  header > .header-container > nav > .login > #sign-up:hover {
    font-size: 0.8rem;
  }

  header > .header-container > nav > .list > a:hover {
    font-size: 0.8rem;
  }

  header > .header-container > nav > h1 {
    font-size: 1.5rem;
  }

  .output > button.Copied {
    font-size: 0.8rem;
  }

  .output > button.Copied:hover {
    font-size: 0.8rem;
  }

  .output > button {
    font-size: 0.8rem;
  }

  .output > button:hover {
    font-size: 0.8rem;
  }

  .input > input {
    font-size: 0.8rem;
  }

  .input > button {
    font-size: 0.8rem;
  }

  .input > button:hover {
    font-size: 0.8rem;
  }

  .input > h1 {
    font-size: 1.5rem;
  }

  .input > p {
    font-size: 0.8rem;
  }

  .input > p > a {
    font-size: 0.8rem;
  }

  .input > p > a:hover {
    font-size: 0.8rem;
  }

  .input > p > a > span {
    font-size: 0.8rem;
  }

  .input > p > a > span:hover {
    font-size: 0.8rem;
  }

  .input > p > a > span > span {
    font-size: 0.8rem;
  }

  .input > p > a > span > span:hover {
    font-size: 0.8rem;
  }

  .input > p > a > span > span > span {
    font-size: 0.8rem;
  }

  .input > p > a > span > span > span:hover {
    font-size: 0.8rem;
  }
}