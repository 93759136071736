#logo {
    color: white;
    font-family: poppins;
    font-weight: 700;
    font-size: 2rem;
}

.footer-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: hsl(260, 8%, 14%);
    color: white; 
    justify-content: center;
    align-items: center;
}

.footer-list {
    font-weight: 500;
    font-size: 0.7rem;
    display: flex;
    justify-content:stretch;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    margin: 2rem 10rem;
}

.footer-list > h3 {
    font-family: poppins;
    font-weight: 500;
    font-size: 1rem;
}

.footer-list > a {
    font-size: 0.8rem;
    text-decoration: none;
    color: white;
}

.footer-list > a:hover {
    color: hsl(180, 66%, 49%);
}

@media only screen and (max-width: 1024px) {
    .footer-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
    }

    .footer-list {
        font-size: 0.5rem;
        margin: 2rem 0rem;
    }

    .footer-list > h3 {
        font-size: 0.8rem;
    }

    .footer-list > a {
        font-size: 0.6rem;
    }
}

@media only screen and (min-width: 768px) {
    .footer-list {
        margin: 2rem 5rem;
    }
}