#logo > h1 {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 2rem;
}

hr {
    border: 1px solid hsl(257, 7%, 63%);
    width: 2%;
}

.brand-promise {
    background-image: url("./illustration-working.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 33%;
    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.list > a {
    text-decoration: none;
    color: hsl(257, 7%, 63%);
    font-family: Poppins;
    font-weight: 500;
}

#tagline {
    font-family: Poppins;
    font-weight: 700;
    font-size: 3.5rem;
    padding: 2rem;
    text-align: left;
    width: 105%;
}

#tagline-2 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.3rem;
    position: relative;
    bottom: 2rem;
    text-align: left;
    width: 100%;
}

#get-started {
    background-color: hsl(180, 66%, 49%);
    border: none;
    border-radius: 1.5rem;
    color: white;
    font-family: Poppins;
    font-weight: 500; 
    padding: 1rem 2rem;

    margin: 0 auto;
    display: inline;
    position: relative;
    bottom: 3rem;

    position: relative;
    left: 2rem;
}

/* Media Queries */

@media only screen and (max-width: 468px) {

    #logo {
        border-bottom: 1px solid hsl(257, 7%, 63%);
    }

    .list > a {
        display: none;
    }

    .more-than > .brand-promise > #tagline {
        font-size: 2rem;
        text-align: center;
        width: 100%;
        padding-right: 1rem;
    }

    .more-than > .brand-promise > #tagline-2 {
        font-size: 1rem;
        text-align: center;
        width: 100%;

        position: relative;
        bottom: 0;

        margin-bottom: 2rem;
    }

    br {
        display: none;
    }

    .container > .more-than > #get-started {
        position: relative;
        left: 7.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .brand-promise {
        background-image: url("./illustration-working.svg");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
    }

    #tagline {
        font-size: 2rem;
        text-align: center;
        width: 100%;
        margin-bottom: 3rem;
    }

    #tagline-2 {
        font-size: 1rem;
        text-align: center;
        width: 100%;
    }

    #get-started {
        display: none;
    }
}


@media only screen and (max-width: 1024px) {
    .brand-promise {
        background-image: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
    }

    #tagline {
        font-size: 2rem;
        text-align: center;
        width: 100%;
        margin-bottom: 3rem;
    }

    #tagline-2 {
        font-size: 1rem;
        text-align: center;
        width: 100%;
    }

    

    .login {
        display: none;
    }

    #get-started {
        display: none;
    }
}